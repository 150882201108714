.activeCustom {
  color: #fff !important;
  background-color: #2196f5 !important;
}

.chart-buttons {
  background-color: #fff;
  border: 1px solid #2196f5;
  color: #2196f5;
}

// icons
@mixin sideNavIcons {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
  width: 26px;
  position: relative;
  top: -4px;
}

.user-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/usersicon.svg');
}
.dashboard-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/dashboard.svg');
}
.category-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/categories.svg');
}
.search-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/table/table-search-icon.svg');
}
.notification-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/notification-bell.svg');
}
.banner-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/banner.svg');
}
.flag-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/flag.png');
}
.ad-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/ad.svg');
}
.payment-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/payment.svg');
}
.contact-us-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/contact-us.svg');
}
.chat-ban-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/chat-ban.png');
}
.campaign-icon {
  @include sideNavIcons();
  background: url('../../../images/icons/campaign.png');
}
.report-icon {
  @include sideNavIcons();
  background-size: cover !important;
  background: url('../../../images/icons/report.svg');
}
.about-icon {
  @include sideNavIcons();
  background-size: cover !important;
  background: url('../../../images/icons/about.svg');
}
.settings-icon {
  @include sideNavIcons();
  background-size: cover !important;
  background: url('../../../images/icons/settings.svg');
}

.nav-links-wrapper {
  display: flex;
  padding: 15px 10px;
  align-items: center;
  gap: 12px;
  border-radius: 5px;
  background-color: #fff;
  margin: 4px auto;
  width: 70%;
  cursor: pointer;
  &.nav-links-active {
    background-color: $primary;
    p {
      color: #fff;
    }
    .user-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/user-active.svg');
    }
    .dashboard-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/dashboard-active.svg');
    }
    .category-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/category-active.svg');
    }
    .sub-category-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/sub-category-active.svg');
    }
    .notification-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/bell-active.svg');
    }
    .banner-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/banner-active.svg');
    }
    .flag-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/flag-active.png');
    }
    .ad-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/ad-active.svg');
    }
    .payment-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/payment-active.svg');
    }
    .contact-us-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/contact-us-active.svg');
    }
    .chat-ban-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/chat-ban-active.png');
    }
    .report-icon {
      @include sideNavIcons();
      background-size: cover !important;
      background: url('../../../images/icons/report-active.svg');
    }
    .campaign-icon {
      @include sideNavIcons();
      background-size: cover !important;
      background: url('../../../images/icons/campaign-active.png');
    }
  }

  .campaign-icon {
    background-size: cover;
  }

  span {
    color: white;
  }

  p {
    color: #455571;
    text-decoration: none;
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  &:hover {
    background-color: $primary;
    .dashboard-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/dashboard-active.svg');
    }

    p {
      color: #fff;
    }

    .category-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/category-active.svg');
    }
    .sub-category-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/sub-category-active.svg');
    }
    .notification-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/bell-active.svg');
    }
    .banner-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/banner-active.svg');
    }
    .flag-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/flag-active.png');
    }
    .ad-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/ad-active.svg');
    }
    .payment-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/payment-active.svg');
    }
    .contact-us-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/contact-us-active.svg');
    }
    .campaign-icon {
      @include sideNavIcons();
      background-size: cover !important;
      background: url('../../../images/icons/campaign-active.png');
    }
    .chat-ban-icon {
      @include sideNavIcons();
      background: url('../../../images/icons/chat-ban-active.png');
    }
    .report-icon {
      @include sideNavIcons();
      background-size: cover !important;
      background: url('../../../images/icons/report-active.svg');
    }
  }
}

.recaptcha-row {
  flex-direction: row;
  display: flex;
  margin: 16px;
}

.recaptcha-row p {
  color: #495057;
  font-weight: 500;
  margin-right: 30px;
}

// table
.table-header-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.table-shadow {
  box-shadow: 0px 3px 6px #fafafa !important;
  border: none !important;
}

.table-custom-header {
  background-color: #fff !important;
}
table .thead-light th {
  background-color: #fff !important;
  border: none !important;
}

.chat-ban-icon, .flag-icon  {
  background-size: cover !important;
}

.table th,
.table td {
  border: none !important;
}

.thead-custom {
  border-top: 1px solid #e5e5e5 !important;
  border-bottom: 1px solid #e5e5e5 !important;
  color: #455571 !important;
  font-weight: bold !important;
}
table {
  border: none !important;
}
body {
  background-color: #f6f7f8 !important;
}
td {
  color: #455571 !important;
  font-weight: 400 !important;
  font-size: 0.8rem;
}

.td-actions-wrapper {
  display: flex;
  width: 100%;

  gap: 10px;
}

.td-action-img {
  max-width: 15px;
  cursor: pointer;
  margin-left: 12px;
}

.userName:hover {
  color: #007bff !important;
  cursor: pointer;
}

.chats-view-btn {
  border: 3px solid #007bff;
  border-radius: 10px;
  min-width: 100px;
  padding: 3px 10px;
  color: #007bff;
  outline: none;
  cursor: pointer;
  font-weight: 300;
  background-color: #fff;
  transition: 0.2s;
}

.chats-view-btn:hover {
  color: white;
  background-color: #007bff;
}

.flex-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table-header-input {
  width: 200px;
  outline: none;

  border: 1px solid #e5e5e5;
  border-radius: 10px;
  color: #bbb4b4;
  padding: 5px;

  background-image: url('../../../images/icons/table/table-search-icon.svg');
  background-position: 170px 7px;
  background-repeat: no-repeat;
}

.btngroup-btn-active {
  border: 1px solid #007bff;
  border-radius: 10px;
  min-width: 60px;
  padding: 3px 10px;
  color: white;
  outline: none;
  cursor: pointer;
  font-weight: 300;
  background-color: #007bff;
  transition: 0.2s;
}

.btngroup-btn-inactive {
  border: 0;
  border-radius: 10px;
  min-width: 60px;
  padding: 3px 10px;
  color: #007bff;
  outline: none;
  cursor: pointer;
  font-weight: 300;
  background-color: #fff;
  transition: 0.2s;
}

.btngroup-container {
  height: 32px;
}

.table-header-button {
  border: 3px solid $primary;
  border-radius: 10px;
  min-width: 100px;
  padding: 3px 10px;
  color: $primary;
  outline: none;
  cursor: pointer;
  font-weight: 300;
  background-color: #fff;
  transition: 0.2s;

  &:hover {
    background-color: $primary;
    color: white;
  }
}
.replied {
  color: #1ca838;
  cursor: pointer;
  font-size: 0.8rem;
}
.ignored {
  color: #d8330a;
  cursor: pointer;
  font-size: 0.8rem;
}
.main-content {
  @media (max-width: 768px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

// table images
.table-sub-category-icon {
  max-width: 60px;
}

.table.align-items-center td,
.table.align-items-center th {
  vertical-align: middle;

  text-align: center;
}

.table-banner-image {
  max-width: 100px;
  overflow: hidden;
}

.table-banner-link {
  color: $primary;
  text-decoration: none;
}

.table-non-reply {
  max-width: 15px;
  cursor: pointer;
}
// slider checkbox

/*-----switch checkbox---*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;

  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 19px;
  width: 19px;
  left: 0px;
  bottom: -5px;
  background-color: #8d8d8d;
  transition: 0.4s;

  -webkit-transition: 0.4s;
}

input:checked + .slider {
  background-color: #76b2eb;
}

input:focus + .slider {
  box-shadow: 0 0 1px #76b2eb;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #2196f5;
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
  height: 8px;
}
.slider.round:before {
  border-radius: 50%;
}

// chart

.doughnut-chart {
  height: 210px;
}

.doughnut-chart-footer {
  background-color: #fff;
  padding: 16px;
  width: 100%;
}
.doughnut-chart-footer-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  p {
    margin: 0 !important;
    padding: 0 !important;
    color: #4d4f5c;
    font-size: 0.8rem;
  }
}

@mixin doughnutCircle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.doughnut-red-circle {
  @include doughnutCircle();
  border: 3px solid #f64456;
}
.doughnut-green-circle {
  @include doughnutCircle();
  border: 3px solid #35cdef;
}
.doughnut-green-2-circle {
  @include doughnutCircle();
  border: 3px solid #4bce98;
}
.doughnut-blue-circle {
  @include doughnutCircle();
  border: 3px solid #7769e4;
}

.doughnut-chart-footer-left {
  display: flex;
  gap: 5px;
  align-items: center;
}

.chart-1 {
  position: relative !important;
  padding: 0px 30px !important;
  padding-bottom: 30px !important;
}

.vertical-tag {
  position: absolute;

  bottom: -28px;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #4d4f5c;
  font-size: 0.8rem;
}
.horizontal-tag {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translate(-50%, -50%);
  color: #4d4f5c;
  font-size: 0.8rem;
  display: inline-block;
  transform: rotate(268deg);
}

.dashboard-form-container {
  width: 100%;
  min-height: 50vh;
  padding-bottom: 45px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #fafafa;
  position: relative;
}

.dashboard-form-header {
  font-size: 1.4rem;
  color: #464646;
  padding: 30px;
  font-weight: 600;
}

.custom-heading {
  font-size: 1.4rem;
  color: #464646;
  padding: 16px;
  font-weight: 600;
  margin-top: 97px;
  background: white;
  margin-bottom: 0;
  padding-bottom: 0;
}

.edit-user-add-btn {
  background-color: #737373;
  border: 3px solid #737373;
  border-radius: 8px;
  padding: 4px;
  width: 100px;
  margin-right: 10px;
  color: white;
}

.edit-user-cancel-btn {
  background-color: white;
  border: 3px solid #737373;
  border-radius: 8px;
  padding: 4px;
  width: 100px;
  color: #737373;
}

.create-note-btn {
  border: 0;
  background: #007bff;
  color: white;
  padding: 2px 15px;
  border-radius: 8px;
}

.custom-type-margin {
  margin-right: 10px;
  margin-bottom: 25px;
}

.padding-0 {
  padding: 0;
}

.more-actions-container {
  border-top: 1px solid #e4e4e4;
  margin-top: 10px;
}

.switch-input {
  display: flex;
  align-items: center;
  margin: 25px 0;
}

.switch-input span {
  margin-right: 20px;
}

.view-user-textarea {
  margin: 0;
}

.form-control:disabled, .form-control[readonly] {
  border-radius: 8px !important;
}

.note-label-view-mode label{
  margin-bottom: 0;
}

.note-label-view-mode {
  margin: 0;
}

.view-user-header-padding {
  margin: 0;
  padding: 16px 30px;
}

.nav-pills .nav-item:not(:last-child) {
  padding-right: 0.5rem !important;
}

.flex-spacebetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  align-items: center;
  padding-right: 30px;
}

.dashboard-form-footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  width: 100%;
  bottom: 30px;
  align-items: center;
}

.form-cancel-button {
  border: 3px solid #737373;
  border-radius: 10px;
  min-width: 100px;
  padding: 3px 10px;
  color: #737373;
  outline: none;
  cursor: pointer;
  font-weight: 300;
  background-color: #fff;
  transition: 0.2s;

  &:hover {
    background-color: #737373;
    color: white;
  }
}
.dashboard-form-body {
  padding: 30px;
  margin: 3rem auto;
}

.dashboard-form-body label {
  color: #737373;
  font-weight: 500;
  font-size: 0.9rem;
}

.choosefile,
.form-control {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative;
}
input[type='file']::file-selector-button {
  background: #f3efef 0% 0% no-repeat padding-box;
  line-height: 1.2;
  padding: 0.2em 0.7em;
  margin-bottom: 10px;
  border-radius: 0.2em;
  border: none;
  transition: 1s;
}
input[type='file'] {
  color: transparent;
  visibility: hidden;
}

.upload-icon {
  background-color: $primary;
  position: absolute;
  right: -4px;
  top: 0px;
  pointer-events: none;
  border-radius: 0px 10px 10px 0px;
  padding: 7px;
  width: 45px;
  text-align: center;
}

.disabled-radio-input:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -4px;
  left: -1px;
  position: relative;
  background-color: grey;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.upload-icon-disabled {
  background-color: grey;
  position: absolute;
  right: -4px;
  top: 0px;
  pointer-events: none;
  border-radius: 0px 10px 10px 0px;
  padding: 7px;
  width: 45px;
  text-align: center;
}

.input-image {
  max-width: 100px;
  margin: 20px;
}
.file-input-name {
  position: absolute;
  top: 4px;
  left: 10px;
  font-size: 0.8rem;
  color: #737373;
}

button[disabled] {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}

// upload
.table-loader {
  position: absolute;
  top: 20px;
  right: 50%;
  transform: translate(-50%, -50%);
  color: #4d4f5c;
  font-size: 0.8rem;
}

label {
  color: #000 !important;
  font-weight: 800 !important;
}
.input-group-append {
  .btn-secondary {
    background-color: $primary;
    color: #fff;
    // border-radius: 0px 10px 10px 0px !important;
  }
  p {
    display: inline-block;
    transform: rotate(90deg);
    margin: 0;
    padding: 0;
    line-height: 0;
  }
}

// pagination
.custom-pagination {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  button {
    background-color: transparent;
    cursor: pointer;
    color: #666666;
    font-size: 1.1rem;
    border: none;
    outline: none;
    margin: 0 5px;
  }

  .pagination-items span {
    margin: 0 5px;
    color: black;
    font-size: 1.1rem;
    cursor: pointer;
    display: inline-block;
    height: 50px;
    width: 50px;
    text-align: center;
    padding: 10px;
  }

  .pagination-items .active {
    background: #2196f5 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 20px #00000008;
    border-radius: 10px;
    color: #fff;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff !important;
  border: 1px solid #ccc;
  border-radius: 1px;
  cursor: not-allowed;
  pointer-events: none;
}

.custom-breadcrumb {
  display: flex;
  gap: 8px;
  padding: 20px 10px;
  @media only screen and (max-width: 600px) {
    position: relative;
    top: -10px;
  }

  .breadcrumb-item-custom {
    font: normal normal bold 1.3rem Noto Sans;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
  }

  .disabled-breadcrumb {
    color: rgba(256, 256, 256, 0.7);
    &:hover {
      color: rgba(256, 256, 256, 1);
    }
  }
}

// welcome
.welcome-back-header {
  display: inline-block !important;
  &::first-letter {
    color: $primary !important;
  }

  .back {
    &:nth-child(2) {
      background: $primary !important;
    }
  }
}

// logo image
.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-width: 100%;
  height: 80px !important;
  max-height: 4rem !important;
}

.web-logo-custom {
  @media only screen and (max-width: 600px) {
    display: none !important;
  }
}

.card-stats-heading-custom {
  color: #8898aa;
  font-size: 0.85rem;
}

.card-stats-custom {
  color: #4d4f5c;
  font-size: 1.2rem;
}

.card-footer-text-custom {
  color: #4d4f5c;
  font-size: 0.7rem;
}

#ct-container {
  z-index: 1000000 !important;
}

.remove-button {
  background-color: transparent;
  color: #3f3f3f;
}
.add-button {
  background-color: $primary;
  color: #fff;
}

.lang-switch-button {
  padding: 10px 15px;
  min-width: 120px;
  font-size: 15px;
  color: $primary;
  background-color: #e8e8e8;
  // position: absolute;
  // bottom: 60px;
  // left: 80px;
  z-index: 10;
  cursor: pointer;
  border-radius: 10px;
  outline: none;
  border: none;
}

.main-content {
  margin-left: 340px;
  margin-right: 60px;
}
.mirrored {
  direction: rtl;
  .navbar-vertical.navbar-expand-md.fixed-left {
    right: 0;
    left: unset;
  }

  .main-content {
    margin-right: 340px;
    margin-left: 60px;
  }
  .table-header-input {
    background-position: 15px 7px;
  }
  .navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 100px;
  }
}

@media screen {
  .navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 340px;
  }
}

.filter-button {
  background-color: transparent;
  color: #455571 !important;
  font-weight: bold;
  cursor: pointer;
  outline: none;

  border: none;
  img {
    max-width: 14px;
    position: relative;
    top: -2px;
  }
}

// background-color: ${(props) => (props.isDragging ? '#ADD8E6' : 'white')};
// cursor: ${(props) => (props.isDragging ? 'grab;' : 'pointer;')}
// border-bottom: ${(props) =>
//   props.isDragging ? '1px solid transparent;' : '1px solid #DFDFDF;'}
// box-shadow: ${(props) =>
//   props.isDragging
//     ? '0px 10px 20px rgba(0, 0, 0, 0.19);'
//     : '1px 1px 1px rgba(0, 0, 0, 0.13);'}

.dragging {
  background-color: #add8e6;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19);
  width: 100%;
  display: flex;
  justify-content: space-around;
}

// phone input
.react-tel-input .form-control {
  border: none !important;
  outline: none !important;
}
.react-tel-input .form-control:focus {
  border: none !important;
  outline: none !important;
}

.hidden-filter {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.css-1s2u09g-control {
  width: 100%;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: auto;
}
// Dropdown height scrollable
.dropdown-menu.show {
  display: block;
  overflow-y: auto;
  max-height: 500px;
}

.modal-title {
  word-break: break-all;
}

.heading-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #f2f2f2;
  align-items: center;
  padding: 8px 16px;
}

.heading-row  p {
  margin: 0;
  font-weight: 600;
  color: #007bff;
}

.single-row {
  padding: 7px 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #f2f2f2;
  border-top: 0;
}

.single-row p {
  margin: 0;
}

.table-data-container {
  max-height: 425px;
  overflow-y: scroll;
}

.history-changes {
  margin-bottom: 10px;
}

.history-changes-title {
  font-weight: bold;
}

.history-changes-value {
  margin-left: 15px;
}

.history-changes-label {
  color: #000;
  display: inline-block;
  font-weight: 500;
}

.disabled-dropdown-btn-color button {
  background: grey !important;
}

// .break-word {
//   word-break: break-all !important;
//   max-width: 100px !important;
// }
// table {
//   width: 100%;
//   table-layout: fixed !important;
// }
td {
  word-wrap: break-word !important;
  word-break: break-all !important;
  white-space: normal !important;
}

.mirrored .react-tel-input .flag-dropdown {
  transform: rotateY(180deg);
}
.mirrored .react-tel-input .form-control {
  padding-right: 0px;
  padding-left: 48px;
}

.mirrored .react-tel-input .country-list {
  transition: auto;
  transform: rotateY(183deg);
  position: relative;
  right: 271px;
}
.mirrored .react-tel-input .flag-dropdown {
  width: 42px;
}

.mirrored .react-tel-input .selected-flag {
  background-color: transparent;
  right: -5px;
}
.mirrored .react-tel-input .selected-flag .arrow {
  left: -13px;
}

.counter-active {
  width: 35px;
  font-weight: bold;
  position: relative;
  top: -13px;
  right: -4px;
  height: 15px;
  font-size: 14px;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0,
    100% 66%,
    62% 67%,
    9% 100%,
    21% 69%,
    0 69%
  );
  clip-path: polygon(0% 0%, 100% 0, 100% 66%, 62% 67%, 9% 100%, 21% 69%, 0 69%);
  background: white !important;
  color: $primary !important;
  padding-left: 8px;
  padding-bottom: 35px;
}
.counter {
  width: 35px;
  font-weight: bold;
  position: relative;
  top: -13px;
  right: -4px;
  height: 15px;
  font-size: 14px;
  -webkit-clip-path: polygon(
    0% 0%,
    100% 0,
    100% 66%,
    62% 67%,
    9% 100%,
    21% 69%,
    0 69%
  );
  clip-path: polygon(0% 0%, 100% 0, 100% 66%, 62% 67%, 9% 100%, 21% 69%, 0 69%);
  background: $primary;
  color: #455571;
  padding-left: 8px;
  padding-bottom: 35px;
}

.aspect-ratio-image {
  visibility: hidden;
  position: absolute;
  top: -9000px;
  left: -9000px;
}

.user-names-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 600px;
  margin: auto;
  padding: 20px 0;
  width: 100%;
}

.receiver-side-single-msg {
  background: #f8f8f8;
  padding: 10px 18px;
  border-radius: 5px;
  width: fit-content;
  min-width: 300px;
  max-width: 800px;
  margin: 10px 0;
  float: left;
}

.receiver-msg {
  margin: 0;
  color: #5b5b5b;
}

.receiver-msg-time {
  margin: 0;
  font-size: 11px;
  // font-weight: bold;
  text-align: end;
}

.sender-side-single-msg {
  background: #cdd6df;
  padding: 10px 18px;
  border-radius: 5px;
  width: fit-content;
  min-width: 300px;
  max-width: 800px;
  margin: 10px 0;
  float: right;
}

.sender-msg {
  margin: 0;
  color: #5b5b5b;
}

.sender-msg-time {
  margin: 0;
  font-size: 11px;
  text-align: end;
  color: #898989;
}

.margin-vertical-0 {
  margin: 0 auto;
}

.clear-both {
  clear: both;
}

.padding-0 {
  padding: 0;
}

.sender-name {
  margin: 0;
  font-size: 11px;
  font-weight: bold;
}

.sender-name:hover {
  text-decoration: none;
}

.chat-view-container {
  max-height: 72vh;
  overflow-y: scroll;
  padding-bottom: 0;
  border-radius: 3px;
}

.custom-bottom {
  bottom: 0;
}

.transparent-background {
  background: transparent;
  border: 0;
  margin-top: 20px;
}


.ql-container {
  min-height: 10em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.ql-toolbar {
  background: #f1f1f1;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor-rtl {
  & .ql-editor {
    text-align: right;
    direction: rtl;
  }
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="0.75em"]::before {
  content: "Small";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1em"]::before {
  content: "Normal";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="1.5em"]::before {
  content: "Large";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="2.5em"]::before {
  content: "Huge";
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
  content: "Small";
  font-size: 0.75em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
  content: "Normal";
  font-size: 1em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
  content: "Large";
  font-size: 1.5em !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
  content: "Huge";
  font-size: 2.5em !important;
}

strong {
  font-weight: bold;
}

@media (max-width: 550px) {
  .mobile-view-container {
    padding: 30px 8px !important;
  }

  .receiver-side-single-msg {
    max-width: 90%;
  }

  .sender-side-single-msg {
    max-width: 90%;
  }
}